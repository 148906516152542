import * as React from 'react';

import Layout from '../components/layout';
import { Seo } from '../components/seo';

const FourOhFourPage = () => {
  return (
    <Layout>
      <main className="relative min-h-screen min-w-screen py-16 px-8 flex flex-col items-center justify-center">
        <div className="container">
          <h1 className="text-6xl font-bold leading-snug">
            The page you're looking for doesn't exist...
          </h1>
        </div>
      </main>
    </Layout>
  );
};

export default FourOhFourPage;

export const Head = () => <Seo title="Not Found" />;
