import React from "react"
import {Link, navigate} from "gatsby";
import { useLocation } from '@reach/router';

import joinClassNames from '../utilities/joinClassNames';
import LogoWhite from '../images/logo-white.svg';
import LogoBlack from '../images/logo-black.svg';
import IconDownBlack from "../images/icon-down-black.svg";
import IconCloseBlack from "../images/icon-close-black.svg";

export default function Layout({ children, navbarTheme, isTemplate, templateTitle }) {
  const location = useLocation();

  return (
    <>
      <nav
        className={joinClassNames(
          navbarTheme === 'home' ? 'navbar-home' :
          navbarTheme === 'black'
            ? 'navbar-black'
            : navbarTheme === 'white'
              ? 'navbar-white'
              : navbarTheme === 'grayLight'
                ? 'navbar-gray-light'
                : 'navbar-gray-dark',
          'fixed top-0 inset-x-0 py-4 px-8 flex items-center z-20'
        )}>
        {isTemplate ? <div className="flex items-center landscape:w-2/5 portrait:w-1/3">
          <button className="flex items-center !text-black overflow-hidden" onClick={() => navigate(-1)}>
            <img src={IconDownBlack} alt="Go Back" className="h-4 mr-3 rotate-90" />
            <div className="truncate">
              <span className="hidden md:inline">{templateTitle}</span>
            </div>
          </button>
        </div> : <div className="flex landscape:w-2/5 portrait:w-1/3">
          <Link to={`/art`} activeClassName="active-link">Art</Link>
          <span
            className={joinClassNames(
              location.pathname === '/art' ||
              location.pathname === '/see'
                ? 'active-link'
                : '',
              'mx-3'
            )}>/</span>
          <Link to={`/see`} activeClassName="active-link">See</Link>
        </div>}

        <Link to={`/`} className="mx-auto">
          <img src={navbarTheme === 'black' || navbarTheme === 'grayDark' || navbarTheme === undefined ? LogoBlack : LogoWhite} alt="Artsee" className="h-8 scale-90"/>
        </Link>

        {isTemplate ? <div className="flex items-center justify-end landscape:w-2/5 portrait:w-1/3">
          <button onClick={() => navigate(-1)}><img src={IconCloseBlack} alt="Close" className="h-5" /></button></div> : <div className="flex justify-end landscape:w-2/5 portrait:w-1/3">
          <Link to={`/info`} activeClassName="active-link">Info</Link>
          <span className={joinClassNames(
            location.pathname === '/info' ||
            location.pathname === '/hello'
              ? 'active-link'
              : '',
            'mx-3'
          )}>/</span>
          <Link to={`/hello`} activeClassName="active-link">Hello</Link>
        </div>}
      </nav>

      {children}
    </>
  )
}